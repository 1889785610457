import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Happy Memorial Day!`}</strong></p>
    <p><strong parentName="p">{`“Murph”`}</strong></p>
    <p>{`1 Mile Run`}</p>
    <p>{`100 Pullups`}</p>
    <p>{`200 Pushups`}</p>
    <p>{`300 Squats`}</p>
    <p>{`1 Mile Run`}</p>
    <p><em parentName="p">{`*`}{`partition out the pull-ups, pushups and squats as needed.  If you
have body armour or a weight vest wear it.`}</em></p>
    <p><strong parentName="p">{`*`}{`*`}{`Today is our Memorial Day Murph! Heats will start every :30
beginning at 9:00am.  If you’ve signed up for a slot please be prepared
to start on time. This a free event for all and if you can’t do the
entire workout don’t fear, we can modify and scale for nearly any
fitness level.  **`}</strong>{`We’ll have a potluck/cookout afterward, around
12:00.  Last names beginning in A-M please bring a side dish, N-Z a
dessert.  Thanks to a generous donation from our member CJ and Seven
Seas Construction all of the meat for grilling will be provided as
well!***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Wednesday at our 10:30am class we’ll have a video shoot of our
new outdoor workout area!  If you’d like to help out by being part of
the video & class please let us know.  We’d love to have a big
turnout.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      